/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ApplicationStatus {
  ALL = "ALL",
  NEEDS_REVIEW = "NEEDS_REVIEW",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
